import { getCDN } from './utils/getEnvironment'

export const apps = [
  {
    imageUrl: `${getCDN()}shuttlepassenger/icon.png`,
    appDescription: 'Shuttle service app for Trenders',
    imageAlt: 'shuttle-app',
    title: 'Shuttle App',
    isNew: true,
    app: {
      foldername: 'shuttlepassenger',
      android: 'shuttlepassenger_v' + process.env.REACT_APP_SHUTTLEPASSENGER,
    },
  },
]
